import { FC } from 'react';

import { useLinkComponent } from '@link';
import { FooterMenuRendering } from '@sitecore/types/manual/Footer';
import { Box, Grid, Heading, NavLink, Stack } from '@sparky';

const FooterMenu: FC<{ fields: FooterMenuRendering['fields'] }> = ({ fields }) => {
  const Link = useLinkComponent();
  return (
    <Grid columns={{ initial: '1', md: '2' }} gap="6" gridTemplateColumns={{ lg: 'repeat(auto-fit, minmax(0, 1fr))' }}>
      {fields.items.map(item => {
        if (!item.fields.items) return null;

        return (
          <Grid.Item key={item.id}>
            {item.fields.title && (
              <Box paddingTop="8">
                <Heading as="h2" color="inherit" size="XS">
                  {item.fields.title.value}
                </Heading>
              </Box>
            )}
            <Box paddingTop="6">
              <Stack as="ul" gap="3">
                {item.fields.items.map(navItem => {
                  const link = navItem?.fields?.link?.value;
                  if (!link?.href) return null;
                  return (
                    <Stack.Item as="li" key={navItem.id}>
                      <Link linkType={link.linktype} linkValue={link}>
                        <NavLink target={link.target} tone="onColor" variant="tertiary">
                          {link.text || navItem.displayName}
                        </NavLink>
                      </Link>
                    </Stack.Item>
                  );
                })}
              </Stack>
            </Box>
          </Grid.Item>
        );
      })}
    </Grid>
  );
};

export default FooterMenu;
